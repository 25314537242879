import * as React from "react"
import { css } from "linaria"

import { blogWidth, linkStyle } from "../consts"

const commonPadding = `padding-left: 1rem; padding-right: 1rem;`;

const cn = {
  text: css`
    font-family: 'Righteous', cursive;
    line-height: 1.3;

    font-weight: normal;
    color: var(--text-color);

    line-height: 1.45;
    hyphens: auto;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;

    ${linkStyle}

    .gatsby-resp-image-image {
      box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1em;
      margin: 1em 0 0.2em;
      ${commonPadding}
    }

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.8em;
    }

    h3 {
      font-size: 1.5em;
    }

    h4 {
      font-size: 1.5em;
    }

    h4 {
      font-size: 1.2em;
    }

    a {
      display: inline;
      white-space: normal;
      word-break: break-word;
    }

    iframe {
      width: 100%;
      display: block;
      ${commonPadding}
    }

    hr {
      display: block;
      border: none;
      border-top: 1px dashed #666;
      margin: 1rem 0 1rem 0;
      position: relative;
      overflow: visible;
      color: #666;
    }

    p {
      margin: 0 0 1em;
      ${commonPadding}
    }

    code {
      font-family: Courier;
      background-color: var(--code-background);
      color: var(--code-color);
      border: 1px solid #aaa;
      padding: 0.1em;
      border-radius: 0.2em;
      ${commonPadding}
    }

    del,
    del a {
      text-decoration: line-through;
    }

    pre {
      background-color: var(--code-background);
      color: var(--code-color);
      padding: 0.2em;
      border-radius: 0.2em;
      overflow-x: scroll;
      font-size: 0.9em;
      ${commonPadding}

      > code {
        width: 100%;
        background: none;
        border: none;
      }
    }

    sup {
      vertical-align: super;
      font-size: 0.75em;
      padding: 0 0.25em;
    }

    sub {
      vertical-align: sub;
      font-size: 0.75em;
      margin: 0 0.25em;
    }

    blockquote {
      padding: 0.01rem 0;
      margin-bottom: 1em;
      background-color: var(--code-background);
      color: var(--code-color);
    }

    ul,
    ol {
      margin-left: 1.3em;
      list-style-type: circle;
      list-style-position: outside;
      margin-bottom: 1em;
      ${commonPadding}
    }

    ol {
      list-style: decimal;
    }

    li {
      margin: 0.2em 0 0.2em;
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    .footnotes {
      font-size: 0.8em;

      p {
        display: inline;
      }
    }

    .footnote-backref {
      text-decoration: none;
      border: none;
    }

    table {
      margin: 1rem 0;
      ${commonPadding}
    }

    td,
    th {
      text-align: center;
      padding: 0.5em 0.5em;
      vertical-align: top;
    }

    tbody {
      border-bottom: 1px solid #ddd;

      td {
        border-top: 1px solid #ddd;
      }
    }

    thead {
      th {
        border-bottom: 0.2rem solid #ddd;
        vertical-align: bottom;
      }
    }
  `,
}

export const Text = ({ html = "", schemaProps }) => {
    return <div
      className={cn.text}
      dangerouslySetInnerHTML={{ __html: html }}
      {...schemaProps}
    />;
}