import * as React from "react"
import { Link } from "gatsby"
import { css } from "linaria"

import { blogWidth } from "../consts"

import { Text } from "./text";

const cn = {
  article: css`
    width: 100%;
    max-width: ${blogWidth};
    margin: 0.5rem auto 0;
    background: var(--text-background);
  `,
  header: css`
    text-align: center;
  `,
  title: css`
    display: block;
    font-weight: bold;
    margin: 0 0 0.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
  `,
  date: css`
    font-weight: bold;
    margin: 0 0 0.5rem;
    font-size: 0.85rem;
    color: #fff;
    opacity: 0.5;
  `,
  section: css`
    padding: 1rem 0;
    margin: 1rem 0;
    width: 100%;
    font-size: 1.1rem;
  `
}

export const Post = ({ post }) => (
  <article className={cn.article} itemscope itemType="http://schema.org/BlogPosting">
    <header className={cn.header}>
      <h1 className={cn.title} itemprop="headline">{post.frontmatter.title}</h1>
      <time className={cn.date} datetime={post.frontmatter.datetime} itemprop="datePublished" pubdate>{post.frontmatter.date}</time>
    </header>
    <section className={cn.section} itemProp="articleBody">
      <Text html={post.html} />
    </section>
  </article>
)
