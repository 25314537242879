import * as React from "react"
import get from "lodash/get"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import { css } from "linaria"

import header from "../../content/presspage/header.jpg";
import { blogWidth, mqTablet, mqMinWidth } from "../consts";
import { Layout } from "../components/layout"
import { Text } from "../components/text"
import { cn as postCn } from "../components/post"

const cn = {
  container: css`
    background: var(--text-background);
    max-width: 45rem;
    margin: 0 auto;
    position: relative;

    @media ${mqTablet} {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-direction: column;
    }
  `,
  headerImage: css`
    display: block;
    width: 100%;
    padding-bottom: 1rem;
  `,
  main: css`
    order: 2;
    font-size: 1.1rem;

    .gatsby-resp-image-wrapper {
      width: 100%;
      margin: 0.5rem 1rem;
    }

    img {
      width: 100%;
      border: 1px solid #fff;
    }
  `,
  side: css`
    font-size: 0.8rem;
    order: 3;
    flex-basis: 10rem;
    
    position: absolute;
    width: 20rem;
    left: -21rem;

    @media ${mqTablet}, ${mqMinWidth} {
      position: static;
      order: 1;
      width: auto;
      font-size: inherit;
    }
  `
}

const PressPage = ({ data, location }) => {
  return (
    <Layout location={location} title={data.main.frontmatter.title}>
      <div className={cn.container}>
        <aside className={cn.side}>
          <img src={header} className={cn.headerImage} />
          <Text html={data.side.html} />
        </aside>
        <main className={cn.main}>
          <Text html={data.main.html} />
        </main>
      </div>
    </Layout>
  )
}

export default PressPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    main: markdownRemark(frontmatter: {type: {eq: "main"}, section: {eq: "presspage"}}) {
      html
      frontmatter {
        title
      }
    }
    side: markdownRemark(frontmatter: {type: {eq: "side"}, section: {eq: "presspage"}}) {
      html
    }
  }

`
